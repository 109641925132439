<template>
  <div>
    <div>
      <v-container grid-list-md fluid>
        <v-layout>
          <v-flex>
            <page-title text="Macchinari"></page-title>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="mt-4">
          <!--macchinario-->
          <v-flex xs12 md5>
            <v-card class="pa-4">
              <v-flex xs12>
                <div class="title mb-5">sitma1150</div>
              </v-flex>

              <v-layout row wrap class="mb-3">
                <v-flex d-flex xs12 sm5 child-flex>
                  <span
                    class="
                      grey--text
                      text--darken-1
                      subheading
                      font-weight-bold
                      ml-1
                    "
                  >
                    Stato</span
                  >
                </v-flex>
                <v-flex d-flex xs12 sm7>
                  <v-icon
                    class="mr-2"
                    v-if="plc.stato"
                    color="green"
                    style="max-width: 32px"
                    >check_circle</v-icon
                  >
                  <v-icon
                    class="mr-2"
                    v-else
                    color="red"
                    style="max-width: 32px"
                    >cancel</v-icon
                  >
                  <span class="title" v-if="plc.stato">In funzione</span>
                  <span class="title" v-else>Non in funzione</span>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mb-3">
                <v-flex d-flex xs12 sm5 child-flex>
                  <span
                    class="
                      grey--text
                      text--darken-1
                      subheading
                      font-weight-bold
                      ml-1
                    "
                  >
                    Funzionamento</span
                  >
                </v-flex>
                <v-flex d-flex xs12 sm7>
                  <v-icon
                    class="mr-2"
                    v-if="plc.inceppata"
                    color="red"
                    style="max-width: 32px"
                    >cancel</v-icon
                  >
                  <v-icon
                    class="mr-2"
                    v-else
                    color="green"
                    style="max-width: 32px"
                    >check_circle</v-icon
                  >
                  <span class="title" v-if="plc.inceppata"
                    >Prodotto inceppato</span
                  >
                  <span class="title" v-else>Nessun problema</span>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mb-3">
                <v-flex d-flex xs12 sm5 child-flex>
                  <span
                    class="
                      grey--text
                      text--darken-1
                      subheading
                      font-weight-bold
                      ml-1
                    "
                  >
                    Velocità impostata</span
                  >
                </v-flex>
                <v-flex d-flex xs12 sm7>
                  <span class="title ml-2">
                    {{ plc.velocita_imp }} copie / min
                  </span>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mb-2">
                <v-flex d-flex xs12 sm5 child-flex>
                  <span
                    class="
                      grey--text
                      text--darken-1
                      subheading
                      font-weight-bold
                      ml-1
                    "
                  >
                    Velocità rilevata</span
                  >
                </v-flex>
                <v-flex d-flex xs12 sm7>
                  <span class="title ml-2">
                    {{ plc.velocita_ril }} copie / min
                  </span>
                </v-flex>
              </v-layout>

              <div class="mt-4 pt-4">
                <v-flex xs12>
                  <h3 class="mb-2">
                    Ricetta
                    <span class="ml-3 body-1">clear</span>
                  </h3>
                  <form v-on:submit.prevent="onSubmit()">
                    <v-radio-group
                      v-model="plc.activeRecipe"
                      column
                      :disabled="plc.stato"
                    >
                      <v-radio
                        label="1 - Test"
                        color="secondary"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        label="2 - Panini Fifa"
                        color="secondary"
                        :value="2"
                      ></v-radio>
                      <v-radio
                        label="3 - Panini Fifa no display"
                        color="secondary"
                        :value="3"
                      ></v-radio>
                    </v-radio-group>

                    <button
                      v-if="!plc.stato"
                      type="submit"
                      class="v-btn theme--light info mt-3"
                    >
                      Salva
                    </button>
                  </form>
                  <p v-if="plc.stato">
                    Non puoi modificare la ricetta mentre la macchina è in
                    funzione
                  </p>
                </v-flex>
              </div>
            </v-card>
          </v-flex>
          <!--macchinario-->
          <v-flex xs12 md5 offset-md1>
            <v-card class="pa-4">
              <v-flex xs12>
                <div class="title mb-5">sitma1002</div>
              </v-flex>

              <v-layout row wrap class="mb-3">
                <v-flex d-flex xs12 sm5 child-flex>
                  <span
                    class="
                      grey--text
                      text--darken-1
                      subheading
                      font-weight-bold
                      ml-1
                    "
                  >
                    Stato</span
                  >
                </v-flex>
                <v-flex d-flex xs12 sm7>
                  <v-icon
                    class="mr-2"
                    v-if="plc2.stato"
                    color="green"
                    style="max-width: 32px"
                    >check_circle</v-icon
                  >
                  <v-icon
                    class="mr-2"
                    v-else
                    color="red"
                    style="max-width: 32px"
                    >cancel</v-icon
                  >
                  <span class="title" v-if="plc2.stato">In funzione</span>
                  <span class="title" v-else>Non in funzione</span>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mb-3">
                <v-flex d-flex xs12 sm5 child-flex>
                  <span
                    class="
                      grey--text
                      text--darken-1
                      subheading
                      font-weight-bold
                      ml-1
                    "
                  >
                    Funzionamento</span
                  >
                </v-flex>
                <v-flex d-flex xs12 sm7>
                  <v-icon
                    class="mr-2"
                    v-if="plc2.inceppata"
                    color="red"
                    style="max-width: 32px"
                    >cancel</v-icon
                  >
                  <v-icon
                    class="mr-2"
                    v-else
                    color="green"
                    style="max-width: 32px"
                    >check_circle</v-icon
                  >
                  <span class="title" v-if="plc2.inceppata"
                    >Prodotto inceppato</span
                  >
                  <span class="title" v-else>Nessun problema</span>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mb-3">
                <v-flex d-flex xs12 sm5 child-flex>
                  <span
                    class="
                      grey--text
                      text--darken-1
                      subheading
                      font-weight-bold
                      ml-1
                    "
                  >
                    Velocità impostata</span
                  >
                </v-flex>
                <v-flex d-flex xs12 sm7>
                  <span class="title ml-2">
                    {{ plc2.velocita_imp }} copie / min
                  </span>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mb-2">
                <v-flex d-flex xs12 sm5 child-flex>
                  <span
                    class="
                      grey--text
                      text--darken-1
                      subheading
                      font-weight-bold
                      ml-1
                    "
                  >
                    Velocità rilevata</span
                  >
                </v-flex>
                <v-flex d-flex xs12 sm7>
                  <span class="title ml-2">
                    {{ plc2.velocita_ril }} copie / min
                  </span>
                </v-flex>
              </v-layout>

              <!-- questo blocco gestisce il cambio ricetta, ora esiste una sola ricetta per questo macchinario, ma serve per l'industria 4.0 -->
              <div class="mt-4 pt-4">
                <v-flex xs12>
                  <h3 class="mb-2">
                    Ricetta
                    <span class="ml-3 body-1">clear</span>
                  </h3>
                  <form v-on:submit.prevent="onSubmit2()">
                    <v-radio-group
                      v-model="plc2.activeRecipe"
                      column
                      :disabled="plc2.stato"
                    >
                      <!-- ATTENZIONE: la macchina ricevente riceve questi value, ma li interpreta con un endianness diversa. Pertanto qui ci sono i corrispondenti
                      little endian dec dei big endian hex che legge la macchina. I valori "veri" sono questi: 256 = 1; 512 = 2; 768 = 3 -->
                      <v-radio
                        label="Tipo 1"
                        color="secondary"
                        :value="256"
                      ></v-radio>
                      <v-radio
                        label="Tipo 2"
                        color="secondary"
                        :value="512"
                      ></v-radio>
                      <v-radio
                        label="Tipo 3"
                        color="secondary"
                        :value="768"
                      ></v-radio>
                    </v-radio-group>

                    <button
                      type="submit"
                      class="v-btn theme--light info mt-3"
                      v-if="!plc2.stato"
                    >
                      Salva
                    </button>
                  </form>
                  <p v-if="plc2.stato">
                    Non puoi modificare la ricetta mentre la macchina è in
                    funzione
                  </p>
                </v-flex>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import { $EventBus } from "@/eventBus";
import PageTitle from "@/components/common/PageTitle";

export default {
  data() {
    return {
      plc: {
        stato: false,
        inceppata: false,
        velocita_imp: 0,
        velocita_ril: 0,
        activeRecipe: 0,
      },
      plc2: {
        stato: false,
        inceppata: false,
        velocita_imp: 0,
        velocita_ril: 0,
        activeRecipe: 0,
      },
    };
  },
  components: {
    PageTitle,
  },
  methods: {
    onSubmit() {
      console.log(this.plc.activeRecipe);
      this.$http({
        method: "get",
        url: "/plc/recipe-change/" + this.plc.activeRecipe,
      })
        .then(() => {
          this.getData();
          $EventBus.$emit("message", {
            type: "success",
            text: "Ricetta impostata correttamente",
          });
        })
        .catch((err) => {
          console.log(err);
          $EventBus.$emit("message", {
            type: "error",
            text: "Impossibile modificare la ricetta in questo momento",
          });
        });
    },
    onSubmit2() {
      console.log(this.plc2.activeRecipe);
      this.$http({
        method: "get",
        url: "/plc2/recipe-change/" + this.plc2.activeRecipe,
      })
        .then(() => {
          this.getData2();
          $EventBus.$emit("message", {
            type: "success",
            text: "Ricetta impostata correttamente",
          });
        })
        .catch((err) => {
          console.log(err);
          $EventBus.$emit("message", {
            type: "error",
            text: "Impossibile modificare la ricetta in questo momento",
          });
        });
    },
    getData() {
      this.$http({
        method: "get",
        url: "/plc/status",
      })
        .then((res) => {
          //console.log(res);
          this.plc = res.data;
        })
        .catch((err) => {
          console.log(err);
          $EventBus.$emit("message", {
            type: "error",
            text: "Impossibile rilevare lo stato del macchinario sitma1150",
          });
        });
    },
    getData2() {
      this.$http({
        method: "GET",
        url: "/plc2/status",
      })
        .then((res) => {
          //console.log(res);
          this.plc2 = res.data;
        })
        .catch((err) => {
          console.log(err);
          $EventBus.$emit("message", {
            type: "error",
            text: "Impossibile rilevare lo stato del macchinario sitma1002",
          });
        });
    },
  },
  mounted() {
    this.getData();
    this.getData2();
    setInterval(this.getData, 500000);
    setInterval(this.getData2, 500000); // intervallo di chiamata API
  },
};
</script>

<style scoped>
button {
  display: block;
}
</style>
